import React from 'react';
import PropTypes from 'prop-types';
import { BallSpinner } from 'react-spinners-kit';

function Loading(props) {
  return (
    <div name="spinner">
    	<BallSpinner size={50} color="#686769" loading={ true } />
    </div>
  );
}

Loading.propTypes = {
  color: PropTypes.string,
};

Loading.defaultProps = {
  color: 'secondary',
};

export default Loading;
