import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import { Divider, FormHelperText, IconButton, InputBase, OutlinedInput, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from "@mui/icons-material/Clear";
import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { CirclePicker } from "react-color";

const CloseButton = styled(IconButton)(({ theme }) => ({
    left: 10,
    color: (theme) => theme.palette.primary.contrastText

}));

const FormFields = styled('div')(({ theme }) => ({
    height: '100%',
    width: '100%',
    flexDirection: 'row',

    alignItems: 'flex-start',
    paddingTop: '10px',

}));

const HSLToRGB = (h, s, l) => {
    s /= 100;
    l /= 100;
    const k = (n) => (n + h / 30) % 12;
    const a = s * Math.min(l, 1 - l);
    const f = (n) =>
        l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
    return [255 * f(0), 255 * f(8), 255 * f(4)];
};

const generateColorArray = () => {
    let colors = [];
    const hues = [0, 50, 140, 190, 240, 280]; // Example hues (in degrees)
    const saturations = [42, 60, 80, 98]; // Example saturations (in percentage)

    for (let hue of hues) {
        const row = [];
        for (let saturation of saturations) {
            const [r, g, b] = HSLToRGB(hue, saturation, 75);
            const color = '#' + [Math.floor(r), Math.floor(g), Math.floor(b)].map(x => {
                const hex = x.toString(16)
                return hex.length === 1 ? '0' + hex : hex
            }).join('')
            row.push(color);
        }
        colors.push(row);
    }

    let res = [];
    for (let j = 0; j < saturations.length; ++j) {
        for (let i = 0; i < hues.length; ++i) {
            res.push(colors[i][j]);
        }
    }

    return res;
}

function IndexUpdate(props) {
    const {
        handleClose,
        saveIndex,
        indexConfigModel,
        saving,
    } = props;


    const { t } = useTranslation();

    const [updatedIndex, setUpdatedIndex] = useState({});

    const [error, setError] = useState('');

    useEffect(() => {
        if (indexConfigModel.index) {
            setUpdatedIndex(indexConfigModel.index);
        }
    }, [indexConfigModel]);

    const isValid = (isNew) => {
        let ret = true;
        const error = {};
        if (!updatedIndex.index_name || updatedIndex.index_name.trim().length === 0) {
            error.index_name = t('THIS_FIELD_IS_REQUIRED');
            ret = false;
        }
        if (!updatedIndex.color) {
            error.color = t('THIS_FIELD_IS_REQUIRED');
            ret = false;
        }
        setError(error);
        console.log('error', error);
        return ret;
    }


    const handleChange = (name) => (event) => {
        if (event && event.target) {
            setUpdatedIndex({ ...updatedIndex, [name]: event.target.value });
        } else {
            setUpdatedIndex({ ...updatedIndex, [name]: event });
        }
    }

    const handleQuestionChange = (index) => (event) => {
        const updatedIndexNew = { ...updatedIndex };
        updatedIndexNew.predefined_questions = [...updatedIndexNew.predefined_questions];
        updatedIndexNew.predefined_questions[index].question = event.target.value;
        setUpdatedIndex(updatedIndexNew);
    }

    const deleteQuestion = (indexToDelete) => {
        const updatedIndexNew = { ...updatedIndex };
        updatedIndexNew.predefined_questions = updatedIndexNew.predefined_questions.filter((_,i) => i != indexToDelete);
        setUpdatedIndex(updatedIndexNew);
    }

    const addQuestion = (startQuestion) => {
        const updatedIndexNew = { ...updatedIndex };
        updatedIndexNew.predefined_questions = [...updatedIndexNew.predefined_questions];
        updatedIndexNew.predefined_questions.push({ question: startQuestion, id: null });
        setUpdatedIndex(updatedIndexNew);
    }

    console.log("updatedIndex", updatedIndex);


    return (

        <Dialog
            maxWidth="md"
            sx={{
                '& .MuiDialog-paper': {
                    width: '100%',
                    height: '80%',
                    margin: 1,
                },
            }}
            open={indexConfigModel.open}
            onClose={handleClose}
        >

            <DialogTitle sx={{
                height: '40px',
                paddingLeft: 5,
                paddingRight: 5,
                display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                width: '100%',
            }}>
                <>
                    <Typography variant='h7'
                        sx={{ color: (theme) => theme.palette.background.contrastText }}>{indexConfigModel.isNew ? t('CREATE') : t('EDIT')}</Typography>
                </>


                <CloseButton aria-label="delete" size="small" onClick={handleClose}>
                    <ClearIcon fontSize="medium" />

                </CloseButton>

            </DialogTitle>


            <DialogContent>

                <FormFields>
                    <form autoComplete='off'>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Divider sx={{ width: '100%', borderBottomWidth: 2 }} />
                            </Grid>
                            <Grid item xs={3}>
                                <Typography align="left" variant='body2'


                                    sx={{ paddingBottom: 5 }}>{t('INDEX_NAME')}</Typography>
                            </Grid>

                            <Grid item xs={9}>
                                <OutlinedInput variant="outlined"
                                    autoFocus
                                    error={!!error.index_name}
                                    helperText={error.index_name}
                                    disabled={!indexConfigModel.isNew}
                                    fullWidth
                                    id="index_name"
                                    value={updatedIndex.index_name || ''}
                                    onChange={handleChange('index_name')}
                                    sx={{
                                        borderWidth: 0,
                                        backgroundColor: "background.grey",
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderWidth: 0,
                                        }
                                    }}
                                />
                                {error.index_name && <FormHelperText error={true}>{error.index_name}</FormHelperText>}
                            </Grid>

                            <Grid item xs={3}>
                                <Typography align="left" variant='body2'


                                    sx={{ paddingBottom: 5 }}>{t('INDEX_DESCRIPTION')}</Typography>
                            </Grid>

                            <Grid item xs={9}>
                                <OutlinedInput
                                    multiline
                                    variant="outlined"
                                    rows={4}
                                    error={error.description}
                                    helperText={error.description}
                                    fullWidth
                                    id="description"
                                    value={updatedIndex.description || ''}
                                    onChange={handleChange('description')}
                                    sx={{
                                        borderWidth: 0,
                                        backgroundColor: "background.grey",
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderWidth: 0,
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider sx={{ width: '100%', borderBottomWidth: 2 }} />
                            </Grid>
                            <Grid item xs={3}>
                                <Typography align="left" variant='body2'


                                    sx={{ paddingBottom: 5 }}>{t('COLOR')}</Typography>
                            </Grid>
                            <Grid item xs={9}>

                                <CirclePicker
                                    color={updatedIndex.color}
                                    onChangeComplete={handleChange("color")}
                                    colors={generateColorArray()}
                                />
                                {error.color && <FormHelperText error={true}>{error.color}</FormHelperText>}

                            </Grid>

                            <Grid item xs={12}>
                                <Divider sx={{ width: '100%', borderBottomWidth: 2 }} />
                            </Grid>

                            <Grid item xs={3}>
                                <Typography align="left" variant='body2'


                                    sx={{ paddingBottom: 5 }}>{t('DEFAULT_QUESTIONS_IN_CHAT')}</Typography>
                            </Grid>

                            <Grid item xs={9}>

                                <Grid container spacing={1}>
                                    {
                                        [0, 1, 2, 3].map((index) => {
                                            if (index < updatedIndex.predefined_questions?.length) {
                                                const predef = updatedIndex.predefined_questions[index];
                                                return (<>
                                                    <Grid item xs={11}>
                                                        <OutlinedInput
                                                            multiline
                                                            maxRows={3}
                                                            variant="outlined"
                                                            fullWidth
                                                            id={`question_${index}`}
                                                            value={predef.question || ''}
                                                            onChange={handleQuestionChange(index)}
                                                            sx={{
                                                                borderWidth: 0,
                                                                backgroundColor: "background.grey",
                                                                '& .MuiOutlinedInput-notchedOutline': {
                                                                    borderWidth: 0,
                                                                }
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <IconButton aria-label="delete" onClick={() => deleteQuestion(index)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </>);
                                            } else if (index === updatedIndex.predefined_questions?.length) {
                                                return (<>
                                                    <Grid item xs={11}>
                                                        <OutlinedInput
                                                            multiline
                                                            maxRows={3}
                                                            variant="outlined"
                                                            fullWidth
                                                            id={`question_${index}`}
                                                            placeholder={t('ADD_QUESTION_HELPER_TEXT')}
                                                            value={''}
                                                            onChange={event => addQuestion(event.target.value)}
                                                            sx={{
                                                                borderWidth: 0,
                                                                backgroundColor: "background.grey",
                                                                '& .MuiOutlinedInput-notchedOutline': {
                                                                    borderWidth: 0,
                                                                }
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1}></Grid>
                                                </>);
                                            } else {
                                                return <></>;
                                            }
                                        })
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </FormFields>

            </DialogContent>

            <DialogActions
                sx={{
                    padding: 0, height: 100, width: '100%',
                }}>
                <Button
                    sx={{
                        width: '150px', margin: 1, color: 'primary.contrastText', backgroundColor: 'background.grey',
                    }}
                    variant="contained"
                    onClick={handleClose}
                >{t('CANCEL')} </Button>


                <Button
                    sx={{
                        width: '150px', margin: 1,
                        backgroundColor: 'primary.third',
                        color: 'primary.selection',
                    }}
                    variant="contained"
                    disabled={saving}
                    loading={saving}
                    onClick={() => {
                        const ret = isValid(indexConfigModel.isNew);
                        if (ret) {
                            saveIndex(updatedIndex);
                        }
                    }}
                > {t('SAVE')}</Button>

            </DialogActions>


        </Dialog>

    );
}

IndexUpdate.propTypes = {};

const mapStateToProps = (store) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(IndexUpdate);


